<i18n>
ru:
  birthdayGift: Подарок на день рождения
  productsForPoints: Блюда за баллы
  favorites: Избранное
  history: История заказов
  notifications: Уведомления
  personalCabinet: Личный кабинет
  points: 'Ваши баллы: '
  profile: Профиль
  showMore: Показать ещё
ua:
  birthdayGift: Подарунок на день народження
  productsForPoints: Страви за бали
  favorites: Вибране
  history: Історія замовлень
  notifications: Повідомлення
  personalCabinet: Особистий кабінет
  points: 'Ваші бали: '
  profile: Профіль
  showMore: Показати більше
us:
  birthdayGift: Birthday gift
  productsForPoints: Products for points
  favorites: Favorites
  history: Order history
  notifications: Notifications
  personalCabinet: Personal cabinet
  points: 'Your points: '
  profile: Profile
  showMore: Show more
</i18n>

<template>
  <lazy-account-personal-cabinet-udun
    v-if="appConfig.VueSettingsPreRun.PersonalCabinetLayout === 'Udun'"
  />
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const accountStore = useAccountStore()
const menuStore = useMenuStore()

onMounted(async () => {
  await accountStore.initBirthdayGifts()
  await accountStore.initPointsHistory()
  await accountStore.loadBalance()
  await menuStore.initAccountPointsMenu()
})

function onNotLoggedInEvent(): void {
  navigateTo(`${appConfig.VueSettingsPreRun.Links.MainLink}?showLoginPopup=true`)
}

onBeforeMount(() => {
  if (accountStore.Profile.state && accountStore.Profile.state !== 'loading' && !accountStore.isLoggedIn)
    onNotLoggedInEvent()
})

watch(
  () => accountStore.Profile.state,
  (newState, oldState) => {
    if (newState && newState !== 'loading' && newState !== oldState && !accountStore.isLoggedIn)
      onNotLoggedInEvent()
  }
)
</script>
